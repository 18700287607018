/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@use 'colors' as colors;
@use 'variables' as v;
@use 'typography' as tp;

@include mat.core();

$black: colors.$black;
$white: colors.$white;

$custom-primary: colors.$custom-primary;
$custom-accent: colors.$custom-accent;
$custom-warn: colors.$custom-warn;
$custom-danger: colors.$custom-danger;

$custom-black: colors.$custom-black;

$klein-blue-palette: (
  50: #e7e9f7,
  100: #c2c7ec,
  200: #99a3df,
  300: #6f80d2,
  400: #4e64c9,
  500: #2649bf,
  600: #2041b4,
  700: #1237a9,
  800: $custom-primary,
  900: #00198a,
  contrast: (
    50: $custom-black,
    100: $custom-black,
    200: $custom-black,
    300: $custom-black,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  )
);

$picton-blue-palette: (
  50: #e4f6fe,
  100: #bae7fd,
  200: #8fd7fb,
  300: #6bc7f7,
  400: $custom-accent,
  500: #4faff2,
  600: #4aa1e3,
  700: #438ece,
  800: #3e7dba,
  900: #325D98,
  contrast: (
    50: $custom-black,
    100: $custom-black,
    200: $custom-black,
    300: $custom-black,
    400: $custom-black,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  )
);

$folly-palette: (
  50: #ffeaef,
  100: #fecad5,
  200: #ef949e,
  300: #e66a79,
  400: $custom-danger,
  500: #f92240,
  600: #e9143e,
  700: #d70038,
  800: #ca0030,
  900: #bb0024,
  contrast: (
    50: $custom-black,
    100: $custom-black,
    200: $custom-black,
    300: $custom-black,
    400: $custom-black,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
  )
);

$beyou-primary: mat.m2-define-palette($klein-blue-palette, 700, 300, 900);
$beyou-accent: mat.m2-define-palette($picton-blue-palette, 400, 100, 700);
// The warn palette is optional (defaults to red).
$beyou-warn: mat.m2-define-palette($folly-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$beyou-theme: mat.m2-define-light-theme((
  color: (
    primary: $beyou-primary,
    accent: $beyou-accent,
    warn: $beyou-warn,
  ),
  typography: tp.$beyou-typography,
  density: 0,
));

// Now apply the theme and typography to the Angular Material components.
@include mat.all-component-themes($beyou-theme);
@include mat.all-component-typographies(tp.$beyou-typography);

html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: v.$font-family-default; 
  color: $custom-black; 
  -webkit-font-smoothing: antialiased;
}
