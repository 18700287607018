$black: #000000;
$white: #ffffff;

$custom-primary: #002D9D;
$custom-accent: #57BAF5;
$custom-warn: #FFBF0F;
$custom-danger: #F2415A;
$custom-success: #27BE69;

$custom-black: #0A0A1F;
$custom-black-icon: #1E2029;
$custom-black-breadcrumb: #1E2029;
$custom-black-light: #2C2C2C;

$custom-grey-dark: #3C3C43;
$custom-grey-darker: rgba(0, 0, 0, 0.50);
$custom-grey-medium-dark: #E0E0E0;
$custom-grey-medium-light: #EEE;
$custom-grey-lighter: #F5F5F5;
$custom-grey-light: #FAFAFA;
$custom-grey-icon-background: #F2F2F7;

$custom-orange-light: #EE9D83;
$custom-purple: #8B5E83;
$custom-salmon: #fa726a;
$custom-deep-sky-blue: #62d2ff;
$custom-slate-blue: #5458f7;
$custom-fuchia: #c248ad;
$custom-lime-green: #1bbf00;

$custom-widget-background-orange: #FCF1EB;
$custom-widget-background-green: #E8F9F4;
$custom-widget-background-pink: #F9EDF7;

