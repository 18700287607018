// Basic settings
$margin-standard: 8px;

// Fonts
$font-family-default: Lato;
$font-family-secondary: Inter;

$border-radius-default: 8px;

// Element dimensions
$sidenav-width: 214px;
$rightside-panel-width: 398px;
$categories-panel-width: 240px;
$category-icon-div-width: 34px;
$panel-header-height: 32px;
$textarea-height: 200px;
$action-button-width: 200px;

// Shadows
$box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.05);
